@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
body {
  font-family: "Lato", sans-serif;
  margin: 0;
}

h1 {
  font-weight: 200;
  font-size: 4rem;
}

p {
  font-weight: 200;
}

ul {
  list-style: none;
}

iframe {
  max-width: 100%;
}

a {
  color: black;
  outline: 0;
}

a:hover {
  text-decoration: none;
}

object {
  width: 100%;
}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.box {
  width: 100%;
  margin-bottom: 1%;
  border-radius: 5px;
  background-color: whitesmoke;
  padding: 2%;
}

.blog-content img {
  max-width: 70%;
  max-height: 400px;
  box-shadow: 2px 2px 4px black;
}

.blog-item {
  margin-bottom: 10px;
  background-color: #eee;
  padding: 30px;
}

.btn-light {
  background-color: #e9e9e9;
}

.back {
  background-image: url(/static/media/back.9d41c83a.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-text {
  text-align: right;
}
.contact {
  margin: auto;
  padding: 4%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(245, 245, 245, 0.564);
}

.film {
  margin: 0;
  padding: 1px;
}

.contents {
  padding: 4%;
  min-height: 100vh;
  text-align: justify;
}

.btn {
  margin-right: 10px;
  margin-bottom: 1%;
}

.composers-dropdown {
  border: none;
  outline: none;
  background-color: #e9e9e9;
  padding: 10px;
  border-radius: 3px;
  margin-bottom: 1%;
  margin-right: 10px;
}

.composers-dropdown:hover {
  cursor: pointer;
}

.event {
  background-color: #eee;
  padding: 4%;
  margin-bottom: 2%;
}

.instrument-gallery-image {
  cursor: pointer;
  width: 100%;
}

.instrument-gallery-image:hover {
  -webkit-filter: saturate(0);
          filter: saturate(0);
}

.gallery-image:hover {
  -webkit-filter: saturate(0);
          filter: saturate(0);
  cursor: pointer;
}

.hero {
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7%;
  color: whitesmoke;
  background-image: url(/static/media/herotest.b1a78bbb.jpg);
  background-position: left center;
  background-attachment: fixed;
  background-size: cover;
}

.choice {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10%;
  margin: 2%;
  color: rgb(5, 76, 94);
  width: 100%;
  height: 300px;
}

.icon {
  color: rgb(5, 76, 94);
  margin-bottom: 3%;
}

.biog {
  font-weight: 100;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}

.store {
  display: flex;
  flex-wrap: wrap;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sorting-buttons {
  display: inline-block;
  width: 100%;
  margin-bottom: 15px;
}

.sharing-buttons {
  text-align: right;
}

.header {
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header h2 {
  margin-bottom: 5%;
}

.item {
  background-color: whitesmoke;
  padding: 4%;
  margin: 1%;
  box-shadow: 5px 5px lightgray;
  border-radius: 10px;
}

.item img {
  width: 65%;
}

.main-nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background-color: black;
  color: white;
}

.nav-links {
  width: 50%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;
}

.photo {
  width: 85%;
}

.photo-container {
  margin: auto;
  display: flex;
  flex-wrap: wrap;
}

.skype-logos {
  display: flex;
  justify-content: center;
}

.skype-logos img {
  height: 50px;
  margin: 5px;
}

.video {
  max-width: 100%;
}

.video-card {
  background-color: #eee;
  padding: 30px;
  margin-bottom: 10px;
}

/*image overlays*/

.main-box {
  width: 100%;
  position: relative;
  float: left;
  margin: 0 4px;
}
.main-box img {
  position: relative;
  z-index: 1;
}

.overlay img {
  width: 70%;
  margin: auto;
}
.overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  opacity: 0;
  color: #fff;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  background-color: #05050598;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  text-align: center;
}
.main-box:hover .overlay {
  opacity: 0.9;
}

@font-face {
  font-family: "billabongregular";
  src: url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.eot");
  src: url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.woff")
      format("woff"),
    url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.ttf")
      format("truetype"),
    url("https://cdn.rawgit.com/milktronics/beaglegr.am/master/public/fonts/billabong-webfont.svg#billabongregular")
      format("svg");
}

.social {
  margin-left: 1%;
}

.portrait {
  width: 100%;
}

.logo {
  width: 35%;
  margin-bottom: 5%;
}

.logos {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.webdev {
  background-image: linear-gradient(
      rgba(250, 250, 250, 0.781),
      rgba(250, 250, 250, 0.795)
    ),
    url("https://images.unsplash.com/photo-1488590528505-98d2b5aba04b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=60");
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

#nav {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* LIGHTBOX ETC */
/* image grid styles */
.img-grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
}

.gallery-img {
  width: 100%;
}

.gallery-img:hover {
  cursor: pointer;
  width: 105%;
  transition: 500ms;
}

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  /* padding controls height, will always be perfectly square regardless of width */
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  min-width: 100%;
  min-height: 100%;
  max-width: 120%;
  position: absolute;
  top: 0;
  right: 0;
}

/* modal styles */
.backdrop {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 40px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

.backdrop h5 {
  text-align: center;
  color: white;
}

#itunes {
  color: steelblue;
  margin-right: 5px;
}

#spotify {
  color: green;
}

.youtube-video {
  display: flex;
  justify-content: center;
}

